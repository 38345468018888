<template>
    <div class="flex flex-col">
        <div ref="chart"></div>
        <div class="description-container flex flex-row">
            <div v-for="(item, index) in data" :key="index" class="flex items-center">
                <div :style="{ backgroundColor: this.colors[index] }" class="color-box mr-2"></div>
                <div class="data-label">{{ item.name }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import * as d3 from "d3";

export default {
    props: ["data"],
    data() {
        return {
            colors: ["#2196f3", "#AA56FF", "#FBBC05", "#05CD99", "#a3aed0"],
        };
    },
    watch: {
        data() {
            this.createChart();
        },
    },
    mounted() {
        this.createChart();
    },
    methods: {
        createChart() {
            d3.select(this.$refs.chart).select("svg").remove();
            const margin = { top: 50, right: 50, bottom: 50, left: 50 };
            const width = 400 - margin.left - margin.right;
            const height = 400 - margin.top - margin.bottom;
            const radius = Math.min(width, height) / 2;

            const svg = d3
                .select(this.$refs.chart)
                .append("svg")
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .append("g")
                .attr("transform", `translate(${width / 2 + margin.left},${height / 2 + margin.top})`);

            const color = d3.scaleOrdinal().range(["#2196f3", "#AA56FF", "#FBBC05", "#05CD99", "#a3aed0"]);
            const assessmentNames = [];
            this.data?.forEach((d) => {
                d.results?.forEach((r) => {
                    assessmentNames.push(r.assessmentName);
                });
            });
            // (d=>d.results.map(r=>r.assessmentName));
            const categories = [...new Set(assessmentNames)];
            console.log("categories::::::::", categories);

            // const maxValue = Math.max(...data.map((d) => Math.max(...d.values)));
            const maxValue = Math.max(...this.data.map((d) => Math.max(...d.results.map((r) => r.totalPoints))));

            const rScale = d3.scaleLinear().domain([0, maxValue]).range([0, radius]);

            const angleSlice = (Math.PI * 2) / categories.length;

            const line = d3
                .lineRadial()
                .radius((d) => rScale(d))
                .angle((d, i) => i * angleSlice);

            const axis = svg.selectAll(".axis").data(categories).enter().append("g").attr("class", "axis");

            axis.append("line")
                .attr("x1", 0)
                .attr("y1", 0)
                .attr("x2", (d, i) => rScale(maxValue * 1) * Math.cos(angleSlice * i - Math.PI / 2))
                .attr("y2", (d, i) => rScale(maxValue * 1) * Math.sin(angleSlice * i - Math.PI / 2))
                .attr("class", "line")
                .style("stroke", "#2195f3")
                .style("stroke-width", "1px");

            const labels = categories.map((label) => {
                if (label.length > 10) return label.substring(0, 10) + "...";
                else return label;
            });
            axis.append("text")
                .attr("class", "legend")
                .style("fill", "#1b2559")
                .style("font-size", "14px")
                .style("font-weight", "bold")
                .attr("text-anchor", "middle")
                .attr("dy", "0.35em")
                .attr("x", (d, i) => rScale(maxValue * 1.25) * Math.cos(angleSlice * i - Math.PI / 2))
                .attr("y", (d, i) => rScale(maxValue * 1.25) * Math.sin(angleSlice * i - Math.PI / 2))
                .text((d, i) => labels[i]);

            const radarLine = svg.selectAll(".radarLine").data(this.data).enter().append("g").attr("class", "radarLine");

            radarLine
                .append("path")
                .attr("class", "radarArea")
                .attr("d", (d) => line(d.results.map((r) => r.totalPoints)))
                .style("fill", (d, i) => color(i))
                .style("fill-opacity", 0.2)
                .style("stroke", (d, i) => color(i))
                .style("stroke-width", "2px");

            radarLine
                .append("path")
                .attr("class", "radarStroke")
                .attr("d", (d) => line(d.results.map((r) => r.totalPoints)))
                .style("fill", "none")
                .style("stroke", (d, i) => color(i))
                .style("stroke-width", "2px");

            // Add 3 lines to link the axes
            const linkLines = svg.append("g").attr("class", "linkLines");

            for (let i = 0; i < categories.length; i++) {
                linkLines
                    .append("line")
                    .attr("x1", rScale(maxValue) * Math.cos(angleSlice * i - Math.PI / 2))
                    .attr("y1", rScale(maxValue) * Math.sin(angleSlice * i - Math.PI / 2))
                    .attr("x2", rScale(maxValue) * Math.cos(angleSlice * ((i + 1) % categories.length) - Math.PI / 2))
                    .attr("y2", rScale(maxValue) * Math.sin(angleSlice * ((i + 1) % categories.length) - Math.PI / 2))
                    .attr("class", "linkLine")
                    .style("stroke", "#2195f3")
                    .style("stroke-width", "1px");
            }
            for (let i = 0; i < categories.length; i++) {
                linkLines
                    .append("line")
                    .attr("x1", rScale((maxValue * 2) / 3) * Math.cos(angleSlice * i - Math.PI / 2))
                    .attr("y1", rScale((maxValue * 2) / 3) * Math.sin(angleSlice * i - Math.PI / 2))
                    .attr("x2", rScale((maxValue * 2) / 3) * Math.cos(angleSlice * ((i + 1) % categories.length) - Math.PI / 2))
                    .attr("y2", rScale((maxValue * 2) / 3) * Math.sin(angleSlice * ((i + 1) % categories.length) - Math.PI / 2))
                    .attr("class", "linkLine")
                    .style("stroke", "#2195f328")
                    .style("stroke-width", "1px");
            }
            for (let i = 0; i < categories.length; i++) {
                linkLines
                    .append("line")
                    .attr("x1", rScale((maxValue * 1) / 3) * Math.cos(angleSlice * i - Math.PI / 2))
                    .attr("y1", rScale((maxValue * 1) / 3) * Math.sin(angleSlice * i - Math.PI / 2))
                    .attr("x2", rScale((maxValue * 1) / 3) * Math.cos(angleSlice * ((i + 1) % categories.length) - Math.PI / 2))
                    .attr("y2", rScale((maxValue * 1) / 3) * Math.sin(angleSlice * ((i + 1) % categories.length) - Math.PI / 2))
                    .attr("class", "linkLine")
                    .style("stroke", "#2195f318")
                    .style("stroke-width", "1px");
            }
            const legend = svg
                .append("g")
                .attr("font-family", "sans-serif")
                .attr("font-size", 10)
                .attr("text-anchor", "end")
                .selectAll("g")
                .attr("class", "legend")
                .attr("transform", (d, i) => `translate(0,${(i - this.data.length / 2) * 20})`);

            legend
                .append("circle")
                .attr("class", "legendIcon")
                .attr("cx", width / 2 + margin.right / 2)
                .attr("cy", height / 2 + margin.bottom / 2)
                .attr("r", 6)
                .style("fill", (d, i) => color(i));

            legend
                .append("text")
                .attr("class", "legendText")
                .attr("x", width / 2 + margin.right / 2 + 10)
                .attr("y", height / 2 + margin.bottom / 2 + 5)
                .text((d) => d.name)
                .style("font-size", "14px")
                .attr("alignment-baseline", "middle");
        },
    },
};
</script>

<style scoped>
.axis .line {
    stroke-dasharray: 4;
}

.axis .legend {
    text-transform: capitalize;
}
.description-container {
    margin-left: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-bottom: 2%;
}
.color-box {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}
</style>
